/* stylelint-disable prettier/prettier */

/**
 * This injects Tailwind's base styles and any base styles registered by
 * plugins.
 */

@tailwind base;

/**
 * This injects Tailwind's component classes and any component classes
 * registered by plugins.
 */

@tailwind components;

/**
 * This injects Tailwind's utility classes and any utility classes registered
 * by plugins.
 */

@tailwind utilities;

/**
 * Use this directive to control where Tailwind injects the responsive
 * variations of each utility.
 *
 * If omitted, Tailwind will append these classes to the very end of
 * your stylesheet by default.
 */
@tailwind variants;

.swiper-pagination-bullet {
    @apply tw-h-3 tw-w-3 tw-rounded-full tw-bg-darkGray-500;
}

.swiper-pagination-bullet-active {
    @apply tw-bg-darkGray-500;
}

a.button, {
    @apply tw-bg-primary-500 tw-py-3 hover:tw-bg-primary-600;
    @apply  hover:tw-no-underline #{!important};
}

.main-navigation .nav > li:last-child {
    @apply tw-bg-primary-500 tw-h-auto tw-transition-colors hover:tw-bg-primary-600;
    @apply  hover:tw-no-underline #{!important};
}

.main-navigation .nav > li:last-child a {
    @apply tw-py-3;
}